
import {
  PropType,
  defineComponent,
  onMounted,
  computed
  // useSlots
} from 'vue'
import lightGallery from 'lightgallery'
import 'lightgallery/css/lightgallery.css'

export interface IImg {
  src: string
  alt: string
  file: string
}
export default defineComponent({
  name: 'Lightgallery',
  props: {
    id: {
      type: String,
      default: 'idLightgallery'
    },
    images: {
      type: Array as PropType<IImg[]>,
      default: () => []
    }
  },
  setup(props) {
    let idLightgallery: any
    const listImg = computed(() => props.images)
    onMounted(() => {
      idLightgallery = document.getElementById(props.id)
      lightGallery(idLightgallery, {
        speed: 500,
        thumbnail: true,
      })
    })
    return {
      listImg
    }
  },
})
